import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Opinions = ({lang = 'en'}) => {
    const query = useStaticQuery(graphql`query {
        opinions: allStrapiOpinions ( filter: { in_home: { eq: true } } ) {
            edges {
                node {
                    name
                    job_position
                    message
                    company_logo_2 {
                        name
                        alternativeText
                        localFile {
                            publicURL
                            childImageSharp {
                                gatsbyImageData(
                                    width: 280
                                    placeholder: NONE
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    photo {
                        name
                        alternativeText
                        localFile {
                            publicURL
                        }
                    }
                    locale
                }
            }
        }
        helpers: allMarkdownRemark ( filter: { frontmatter: { name: { eq: "helpers" } } }) {
            edges {
                node {
                    frontmatter {
                        lang
                        opinions {
                            title
                            button
                        }
                    }
                }
            }
        }
    }`)
    const opinions = query.opinions.edges.filter(item => item.node.locale === lang)
    const helpers = query.helpers.edges.filter((item) => item.node.frontmatter.lang === lang)[0].node.frontmatter

    const urlKnowledgeCenter = {
        "es" : "/es/knowledge-center#case-studies",
        "en" : "/knowledge-center#case-studies"
    }

    const [selectedOpinionIndex, setSelectedOpinionIndex] = useState(0)
    const [nextOpinionAvailable, setNextOpinionAvailable] = useState(true)
    const [prevOpinionAvailable, setPrevOpinionAvailable] = useState(true)

    const nextOpinion = () => {
        if (selectedOpinionIndex === (opinions.length - 1)) {
            setSelectedOpinionIndex(0)
            return
        }

        setSelectedOpinionIndex(selectedOpinionIndex + 1)
    }

    const prevOpinion = () => {
        if (selectedOpinionIndex === 0) {
            setSelectedOpinionIndex(opinions.length - 1)
            return
        }

        setSelectedOpinionIndex(selectedOpinionIndex - 1)
    }

    const selectOpinionIndex = (index) => {
        setSelectedOpinionIndex(index)
        //setPrevOpinionAvailable(index > 0)
        //setNextOpinionAvailable((opinions.length - 1) > index)
    }

    return <div className="main__section main__section--100 ">
        <div className="container container--gray">
            <h2>{helpers.opinions.title}</h2>
            <div className="carrousel">
                <div className="carrousel__content">
                    <div className="carrousel__content__button">
                        <button className="button-icon button-icon--secondary button-icon--lg" disabled={!prevOpinionAvailable} onClick={prevOpinion}>
                            <i className="icon-arrow-left"></i>
                        </button>
                    </div>

                    <div className="carrousel__content__box">
                        {opinions.map((item, index) => {
                            return <div className={`box__testimonial ${selectedOpinionIndex === index ? 'box__testimonial--show' : ''}`} key={index}>
                                <div className="box__testimonial__image">
                                    <GatsbyImage alt={item.node.company_logo_2.alternativeText} image={getImage(item.node.company_logo_2.localFile)} />
                                    {/*<img src={item.node.company_logo_2.localFile.publicURL} alt={item.node.company_logo_2.alternativeText} />*/}
                                </div>
                                <div className="box__testimonial__text">
                                    <div className="text__customer">
                                        {item.node.message}
                                    </div>
                                    <div className="text__name">{item.node.name}</div>
                                    <div className="text__position">{item.node.job_position}</div>
                                </div>
                            </div>
                        })}
                    </div>

                    <div className="carrousel__content__button">
                        <button className="button-icon button-icon--secondary button-icon--lg" disabled={!nextOpinionAvailable} onClick={nextOpinion}>
                            <i className="icon-arrow-right"></i>
                        </button>
                    </div>
                </div>
                <div className="carrousel__dots">
                    {opinions.map((item, index) => {
                        return <span className={`dots__dot ${selectedOpinionIndex === index ? 'dots__dot--active' : ''}`} key={index} onClick={() => selectOpinionIndex(index)}/>
                    })}
                </div>
            </div>
            <Link to={urlKnowledgeCenter[lang]} className="link--big">
                {helpers.opinions.button} 
                <i className="icon-arrow-right"></i>
            </Link>
        </div>
    </div>
}

export default Opinions
