import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Seo from "../components/seo"
import NewLayout from "../components/new_layout"
import rehypeRaw from "rehype-raw"
import ReactMarkdown from "react-markdown"
import CardPost from "../components/v2023/UI/Card/card-post"
import Opinions from "../components/v2023/Opinions/opinions"
import Certifications from "../components/v2023/Certifications/certifications"
import Identity from "../components/v2023/Identity/identity"
import { getFirstText } from "../utils/gatsby-helpers"

const KnowledgeCenter = ({ data, pageContext }) => {
	const lang = pageContext.langKey;
	const page = data.page;
	const webinars = data.webinars.edges;
	const caseStudies = data.caseStudies.edges;
	const whitePapers = data.whitePapers.edges;
	const posts = data.posts.edges;
	const guides = data.guides.edges;

	const pathBlog = lang === 'en' ? '/blog' : `/${lang}/blog`;
	const pathWebinars = lang === 'en' ? '/webinars' : `/${lang}/webinars`;
	const pathWhitePapers = lang === 'en' ? '/ebooks-whitepapers' : `/${lang}/ebooks-whitepapers`;
	const pathCaseStudy = lang === 'en' ? '/case-studies' : '/es/casos-exito';
	const pathGuides = lang === 'en' ? '' : `/${lang}`;

	const [selectedIndexSolution, setSelectedIndexSolution] = useState(0);

	let [limitCaseStudies, setLimitCaseStudies] = useState(3);
	let [limitWebinars, setLimitWebinars] = useState(4);
	let [limitWhitePapers, setLimitWhitePapers] = useState(4);
	let [limitPosts, setLimitPosts] = useState(4);
	let [limitGuides, setLimitGuides] = useState(4);

	const webinarsArray = webinars
		.filter((webinar) => webinar.node.important)
		.map((webinar, index) => ({ ...webinar, key: `webinar-${index}` }));
	const whitePapersArray = whitePapers
		.filter((whitePaper) => whitePaper.node.important)
		.map((whitePaper, index) => ({ ...whitePaper, key: `white-paper-${index}` }));
	const webinarsAndWhitePapers = webinarsArray.concat(whitePapersArray).sort((item1, item2) => {
		const order1 = item1.node.order;
		const order2 = item2.node.order;
		if (order1 === null) {
			return 1;
		}
		if (order2 === null) {
			return -1;
		}
		return item1.node.order - item2.node.order;
	});

	let carouselItems = [];
	carouselItems = webinarsAndWhitePapers.map((item) => item.key);

	const [carouselItemSelected, setCarouselItemSelected] = useState(carouselItems[0]);
	const [nextCarouselAvailable, setNextCarouselAvailable] = useState(true);
	const [prevCarouselAvailable, setPrevCarouselAvailable] = useState(true);

	const nextCarousel = () => {
		const index = carouselItems.indexOf(carouselItemSelected);

		if (carouselItems.length - 1 === index) {
			setCarouselItemSelected(carouselItems[0]);
			return;
		}

		setCarouselItemSelected(carouselItems[index + 1]);
		//setNextCarouselAvailable((carouselItems.length - 1) > (index + 1))
		//setPrevCarouselAvailable(true)
	};

	const prevCarousel = () => {
		const index = carouselItems.indexOf(carouselItemSelected);

		if (index === 0) {
			setCarouselItemSelected(carouselItems[carouselItems.length - 1]);
			return;
		}

		setCarouselItemSelected(carouselItems[index - 1]);
		//setPrevCarouselAvailable((index - 1) > 0)
		//setNextCarouselAvailable(true)
	};

	const selectCarouselItem = (item) => {
		const index = carouselItems.indexOf(carouselItemSelected);

		setCarouselItemSelected(item);
		//setPrevCarouselAvailable(index > 0)
		//setNextCarouselAvailable((carouselItems.length - 1) > index)
	};

	const showMoreCaseStudies = () => {
		if (limitCaseStudies < caseStudies.length) {
			setLimitCaseStudies(limitCaseStudies + 3);
		}
	};

	const showMoreWebinars = () => {
		if (limitWebinars < webinars.length) {
			setLimitWebinars(limitWebinars + 4);
		}
	};

	const showMoreWhitePapers = () => {
		if (limitWhitePapers < whitePapers.length) {
			setLimitWhitePapers(limitWhitePapers + 4);
		}
	};

	const showMorePosts = () => {
		if (limitPosts < posts.length) {
			setLimitPosts(limitPosts + 4);
		}
	};

	const showMoreGuides = () => {
		if (limitGuides < guides.length) {
			setLimitGuides(limitGuides + 4);
		}
	};

	return (
		<NewLayout pageContext={pageContext}>
			<Seo
				lang={pageContext.langKey}
				title={page.seo.title}
				description={page.seo.meta_description}
				image={page.seo?.image_2?.localFile?.publicURL}
				translates={pageContext.translates}
			/>

			<main className="main">
				<div className="main__section main__section--100 white-section brown">
					<div className="container" data-aos="fade-up">
						<div className="container__text">
							<h1 className="merriweather merriweather--thin">{page.title}</h1>
						</div>
						<div className="carrousel">
							<div className="carrousel__content">
								<div className="carrousel__content__button">
									<button
										className="button-icon button-icon--secondary button-icon--lg"
										disabled={!prevCarouselAvailable}
										onClick={prevCarousel}
									>
										<i className="icon-arrow-left"></i>
									</button>
								</div>

								<div className="carrousel__content__box">
									{webinarsAndWhitePapers.map((item, index) => {
										const type = item.key.split('-').slice(0, -1).join('-');
										const url = type === 'white-paper' ? pathWhitePapers : pathWebinars;
										const image =
											type === 'white-paper' ? item.node.seo.image_2 : item.node.thumbnail_2;
										return (
											<CardPost
												key={item.key}
												lang={lang}
												name={item.node.name}
												image={image}
												url={`${url}/${item.node.url}`}
												description={item.node.seo.meta_description}
												horizontal={true}
												customClass={
													carouselItemSelected === item.key
														? 'card--post--horizontal--show'
														: null
												}
											/>
										);
									})}
								</div>

								<div className="carrousel__content__button">
									<button
										className="button-icon button-icon--secondary button-icon--lg"
										disabled={!nextCarouselAvailable}
										onClick={nextCarousel}
									>
										<i className="icon-arrow-right"></i>
									</button>
								</div>
							</div>
							<div className="carrousel__dots">
								{carouselItems.map((item, index) => (
									<a
										className={`dots__dot ${
											carouselItemSelected === item ? 'dots__dot--active' : ''
										}`}
										key={index}
										onClick={() => selectCarouselItem(item)}
									/>
								))}
							</div>
						</div>
					</div>
				</div>

				<div className="main__section main__section--100 color-section purple" id="case-studies">
					<div className="container">
						<ReactMarkdown children={page.description} rehypePlugins={[rehypeRaw]} />
						<div className="grid-lg-3">
							{caseStudies.slice(0, limitCaseStudies).map((item, index) => (
								<CardPost
									key={index}
									name={item.node.title}
									image={item.node.logo_new}
									url={`${pathCaseStudy}/${item.node.url}`}
									description={getFirstText(item.node.seo.meta_description)}
								/>
							))}
						</div>
						{limitCaseStudies < caseStudies.length && (
							<Link to={page.cta_show_more_button.url} className="button button--secondary button--xl">{page.cta_show_more_button.text}</Link>
						)}
					</div>
				</div>
				<div className="main__section main__section--100">
					<div className="container">
						<div className="tabs tabs--pills-big">
							<div
								className={`tabs__tab ${selectedIndexSolution === 0 ? 'tabs__tab--active' : ''}`}
								onClick={() => setSelectedIndexSolution(0)}
							>
								{page.guides_title}
							</div>
							<div
								className={`tabs__tab ${selectedIndexSolution === 2 ? 'tabs__tab--active' : ''}`}
								onClick={() => setSelectedIndexSolution(2)}
							>
								eBooks
							</div>
							{webinars.length > 0 && (
								<div
									className={`tabs__tab ${selectedIndexSolution === 1 ? 'tabs__tab--active' : ''}`}
									onClick={() => setSelectedIndexSolution(1)}
								>
									Webinars
								</div>
							)}
							<div
								className={`tabs__tab ${selectedIndexSolution === 3 ? 'tabs__tab--active' : ''}`}
								onClick={() => setSelectedIndexSolution(3)}
							>
								Blog
							</div>
						</div>
						<div className={`tabs__content  ${selectedIndexSolution === 0 ? 'tabs__content--show' : ''}`}>
							<div className="grid-lg-2">
								{guides.slice(0, limitGuides).map((guide, index) => (
									<CardPost
										key={index}
										lang={lang}
										url={`${pathGuides}/${guide.node.url}`}
										image={guide.node.seo.image_2}
										name={guide.node.title}
										description={getFirstText(guide.node.seo.meta_description)}
									/>
								))}
							</div>
							{limitGuides < guides.length && (
								<button className="button button--secondary button--xl" onClick={showMoreGuides}>
									{page.cta_button}
								</button>
							)}
						</div>
						<div className={`tabs__content  ${selectedIndexSolution === 1 ? 'tabs__content--show' : ''}`}>
							<div className="grid-lg-2">
								{webinars.slice(0, limitWebinars).map((webinar, index) => (
									<CardPost
										key={index}
										lang={lang}
										url={`${pathWebinars}/${webinar.node.url}`}
										image={webinar.node.thumbnail_2}
										name={webinar.node.name}
										description={getFirstText(webinar.node.seo.meta_description)}
									/>
								))}
							</div>
							{limitWebinars < webinars.length && (
								<button className="button button--secondary button--xl" onClick={showMoreWebinars}>
									{page.cta_button}
								</button>
							)}
						</div>
						<div className={`tabs__content  ${selectedIndexSolution === 2 ? 'tabs__content--show' : ''}`}>
							<div className="grid-lg-2">
								{whitePapers.slice(0, limitWhitePapers).map((item, index) => (
									<CardPost
										key={index}
										lang={lang}
										url={`${pathWhitePapers}/${item.node.url}`}
										image={item.node.seo.image_2}
										name={item.node.title}
										description={getFirstText(item.node.seo.meta_description)}
									/>
								))}
							</div>
							{limitWhitePapers < whitePapers.length && (
								<button className="button button--secondary button--xl" onClick={showMoreWhitePapers}>
									{page.cta_button}
								</button>
							)}
						</div>
						<div className={`tabs__content  ${selectedIndexSolution === 3 ? 'tabs__content--show' : ''}`}>
							<div className="grid-lg-2">
								{posts.slice(0, limitPosts).map((item, index) => (
									<CardPost
										key={index}
										lang={lang}
										url={`${pathBlog}/${item.node.url}`}
										image={item.node.photo_2}
										name={item.node.name}
										description={getFirstText(item.node.seo.meta_description)}
									/>
								))}
							</div>
							{limitPosts < posts.length && (
								<button className="button button--secondary button--xl" onClick={showMorePosts}>
									{page.cta_button}
								</button>
							)}
						</div>
					</div>
				</div>
				<Opinions lang={lang} />
				<Certifications />
				<Identity lang={lang} />
			</main>
		</NewLayout>
	);
};

export default KnowledgeCenter

export const KnowledgeCenterQuery = graphql`
    query ($langKey: String) {
        page: strapi2022KnowledgeCenter ( locale: { eq: $langKey } ) {
            seo {
                title
                meta_description
                image_2 {
                    localFile {
                        publicURL
                    }
                }
            }
            title
            description
            cta_button
            cta_show_more_button {
                text
                url
            }
            guides_title
        }
        webinars: allStrapiWebinars (
            filter: { locale: { eq: $langKey } }
            sort: { fields: [published_at], order: [DESC] }
        ) {
            edges {
                node {
                    seo {
                        title
                        meta_description
                    }
                    url
                    name
                    description
                    important
					order
                    date
                    time_start
                    thumbnail_2 {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 930
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                }
            }
        }
        whitePapers: allStrapiWhitePapers (
            filter: { locale: { eq: $langKey } }
            sort: { fields: [published_at], order: [DESC] }
        ) {
            edges {
                node {
                    seo {
                        title
                        meta_description
                        image_2 {
                            name
                            alternativeText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        width: 930
                                        placeholder: BLURRED
                                        formats: [WEBP]
                                    )
                                }
                            }
                        }
                    }
                    url
                    title
                    description
                    important
					order
                }
            }
        }
        posts: allStrapiBlogPosts (
            filter: { locale: { eq: $langKey } }
            sort: { fields: [creation_datetime], order: [DESC] }
        ) {
            edges {
                node {
                    seo {
                        title
                        meta_description
                    }
                    url
                    name
                    content
                    photo_2 {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 930
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                }
            }
        }
        caseStudies: allStrapiCaseStudy (
            filter: { locale: { eq: $langKey } }
            sort: { fields: [published_at], order: [DESC] }
        ) {
            edges {
                node {
                    seo {
                        title
                        meta_description
                    }
                    url
                    title
                    content {
                        description
                    }
                    logo_new {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 930
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                }
            }
        }
        guides: allStrapiGenericLandingPage (
            filter: { locale: { eq: $langKey }, show_in_guides: { eq: true } }
            sort: { fields: [guide_order], order: [ASC] }
        ) {
            edges {
                node {
                    seo {
                        meta_description
                        image_2 {
                            name
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        width: 930
                                        placeholder: BLURRED
                                        formats: [WEBP]
                                    )
                                }
                            }
                        }
                    }
                    title
                    description
                    url
                }
            }
        }
        helpers: markdownRemark (
            frontmatter: {
                lang: {
                    eq: $langKey
                }
                name: {
                    eq: "helpers"
                }
            }
        ) {
            frontmatter {
                trust_us
                last_news
                blog_path
                about_us_path
                request_demo_path
            }
        }
    }`
